// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Avatar, Col, Dropdown, Menu, Row } from "antd";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";
import { CgMenuGridO } from "react-icons/cg";

import { WrapperActionsRight, WrapperHeader } from "../layouts";
import { useNavBar } from "./hooks";

export const NavBar = () => {
  const { user, logout, changeCollapsed } = useNavBar();

  const menu = (
    <Menu
      items={[
        {
          key: "logout",
          label: <a onClick={logout}>Cerrar Sesión</a>,
        },
        {
          key: "user",
          label: `${user?.first_name} ${user?.last_name}`,
        },
        {
          key: "build",
          label: `Creado: ${moment(`${user?.createdAt}`)
            .add("h", 5)
            .format("DD/MM/YYYY HH:mm:ss")}`,
        },
      ]}
    />
  );

  return (
    <WrapperHeader>
      <Row align="middle" style={{ width: "100%", paddingRight: 10 }}>
        <Col style={{ display: "flex", justifyContent: "flex-start" }}>
          <CgMenuGridO
            size={28}
            color="var(--primary)"
            style={{ cursor: "pointer" }}
            onClick={changeCollapsed}
          />
          <img
            src={
              "/logo.png"
              // "https://casandra-static.s3.amazonaws.com/static/path_logo/5ae95782-54de-43bd-b842-08fcadb09c21_logo_casandra.png"
            }
            alt="logoYoyoso"
            style={{
              width: "200px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            flexGrow: 1,
          }}
        />
        <WrapperActionsRight>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <Avatar size={26} icon={<UserOutlined />} />
          </Dropdown>
        </WrapperActionsRight>
      </Row>
    </WrapperHeader>
  );
};
