// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ReactNode, useEffect, useState } from "react";
import { Avatar, Button, Col, message, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { useOrderById } from "../../../../modules/orders/hooks/orderById";
import { WrapperModalGenerate } from "../../../../modules/orders/ui/styled";
import {
  selectOrderModalIsOpen,
  selectOrderShipping,
  selectOrderShippingID,
} from "../../../../redux/selectors/orders.selectors";
import { PATH_S3_BUCKET } from "../../../utils/constants";
import { useParams } from "react-router-dom";
import {
  InputTextSimple,
  SimpleInputNumber,
  SimpleInputSelect,
  SimpleInputTextArea,
} from "../inputs";
import {
  fulfillmentCompanyService,
  ordersDetailsService,
  shippingQuantityService,
  shippingService,
} from "../../../services";
import { OrderDetail } from "../../../../redux/@types";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { changeOrderModalIsOpen } from "../../../../redux";
import { ShippingStatus } from "../../../../redux/@types/orders";

const { Title } = Typography;

export interface DataShipping {
  id: number;
  name: string;
  description: string;
  path: null;
  type: string;
  status: string;
  integration: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
}

interface PropsModal {
  title: string;
  maskClosable?: boolean;
  destroyOnClose?: boolean;
  closable?: boolean;
  onCancel?: any;
  footer?: ReactNode;
  width?: number | string;
  height?: number | string;
}
// 1 = "En preparación"
// 2 = ""
// 3 = "Entregado"
// 4 = ""
// let shippingStatus_ids = [1,2,3,4]

export const ModalGenerate = ({
  title,
  maskClosable = true,
  destroyOnClose = true,
  closable = true,
  onCancel = null,
  footer = null,
  width = 650,
  height = 600,
}: PropsModal) => {
  const { changeOrderModal } = useOrderById();
  const params = useParams();
  const dispatch = useAppDispatch();
  const orderShipping = useSelector(selectOrderShipping);
  const orderModalIsOpen = useSelector(selectOrderModalIsOpen);
  const shippingIDForAdd = useSelector(selectOrderShippingID);
  const [valueSelector, setValueSelector] = useState<string | undefined>();
  const [valNum, setValNum] = useState({});
  const [valueText, setValueText] = useState<string | undefined>();
  const [valueGuia, setValueGuia] = useState<string | undefined>();
  const [shipping, setShipping] = useState<DataShipping[] | []>([]);
  const [data, setData] = useState<OrderDetail[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const changeSelectValue = (val: string) => {
    setValueSelector(val);
  };
  console.log({ orderShipping });
  const changeNumVal = (val: number, name: string) => {
    setValNum({ ...valNum, [name]: val });
  };
  const getOptionsShipmentsServices = async () => {
    return await fulfillmentCompanyService
      .find({ query: { $limit: 9999999999999 } })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        return message.error(err.message);
      });
  };
  const getOrderDetailsServices = async () => {
    return await ordersDetailsService
      .find({
        query: {
          order_id: Number(params.id),
          $limit: 9999999999999,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        return message.error(err.message);
      });
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    if (!valueSelector) {
      setIsLoading(false);
      return message.error("Por favor seleccione un método de envío.");
    }
    await shippingService
      .patch(orderShipping.find((item) => item.shipping_status_id !== 3).id, {
        shipping_status_id: 2,
        fulfillment_company_service_code: undefined,
        fulfillment_company_id: Number(valueSelector),
        // private_notes: valueSelector === "1" ? valueText : undefined,
        private_notes: valueText,
        delivery_number: valueGuia,
      })
      .then((res) => {
        console.log("resOfSHIPPING====>", res);
        setIsLoading(false);
        // changeOrderModal();
        dispatch(changeOrderModalIsOpen());
        message.success("Se ha generado el envío exitosamente");
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message);
      });
  };
  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Foto",
      dataIndex: "product_main_image",
      key: "product_main_image",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <Avatar
            size={64}
            src={`${PATH_S3_BUCKET}/${item.product_main_image}`}
          />
        </Col>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Cantidad Pendiente de Envío",
      dataIndex: "quantity",
      key: "quantity",
      render: (value, record) => `${Number(value) - Number(record.sent)}`,
    },
    {
      title: "Cantidad a Enviar",
      dataIndex: "quantity_sent",
      key: "quantity_sent",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <SimpleInputNumber
            placeHolder="Cantidad"
            onChange={(val) => changeNumVal(val, item.product_name)}
            value={valNum[`${item.product_name}`] || 0}
            max={item.quantity}
          />
          <Button
            type="primary"
            style={{
              marginLeft: 6,
            }}
            icon={<SaveOutlined />}
            onClick={() =>
              changeQuantityForShipping(
                item.id,
                valNum[`${item.product_name}`]
                // item
              )
            }
            // disabled={item?.shippings_details?.[0]?.quantity! || false}
          >
            Agregar
          </Button>
        </Col>
      ),
    },
    {
      title: "Cantidad Agregada",
      dataIndex: "quantity_sent_1",
      key: "quantity_sent_1",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            {item?.shippings_details?.[0]?.quantity! && (
              <Button
                onClick={() =>
                  deleteQuantityForShipping(item.shippings_details[0].id)
                }
                style={{ borderRadius: "9px" }}
                type="primary"
                danger
              >
                ({item.shippings_details[0].quantity}) <DeleteOutlined />
              </Button>
            )}
          </Col>
        );
      },
    },
  ];

  useEffect(() => {
    if (shipping.length === 0) {
      getOptionsShipmentsServices().then((res: any) => {
        setShipping(res.data);
      });
      getOrderDetailsServices().then((res: any) => {
        setData(res.data.filter((it) => it.sent < it.quantity));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteQuantityForShipping = async (id: number) => {
    return await shippingQuantityService
      .remove(id)
      .then((res: any) => {
        console.log(res);
        return getOrderDetailsServices().then((res: any) => {
          setData(res.data.filter((it) => it.sent < it.quantity));
        });
      })
      .catch((err: any) => {
        console.log(err.message);
        return err.message;
      });
  };

  const createQuantityForShipping = async (
    order_detail_id: number,
    quantity: number
  ) => {
    return await shippingQuantityService
      .create({
        order_detail_id,
        quantity,
        order_id: Number(params.id),
        shipping_id: shippingIDForAdd,
      })
      .then((res) => {
        message.success(
          `Se ha agregado con éxito la cantidad deseada del producto: ${order_detail_id}`
        );
        //actualizar data
        getOrderDetailsServices().then((res: any) => {
          setData(res.data.filter((it) => it.sent < it.quantity));
        });
        return res;
      })
      .catch((err: any) => {
        console.log(err);
        return message.error(err.message);
      });
  };

  const changeQuantityForShipping = (
    order_detail_id: number,
    quantity: number
    // testinttttt: any
  ) => {
    // console.log({ quantity, order_detail_id, testinttttt });
    if (quantity < 0 || !quantity)
      return message.error("La cantidad debe ser mayor a 0");
    createQuantityForShipping(order_detail_id, quantity);
  };

  return (
    <WrapperModalGenerate
      centered
      width={width}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      closable={closable}
      title={title}
      style={{ top: 20, height: height }}
      open={orderModalIsOpen}
      onCancel={changeOrderModal}
      footer={footer}
    >
      <div className={`modal-content ${title ? "headline" : ""}`}>
        <Row
          justify="start"
          style={{ width: "100%", paddingBottom: 10 }}
          gutter={[8, 8]}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title
              style={{ marginBottom: "12.5px", color: "var(--primary)" }}
              level={3}
            >
              Entrega:
            </Title>
            <SimpleInputSelect
              valueSelect={valueSelector}
              onChange={changeSelectValue}
              label={"Seleccione compañía"}
              dataOptions={
                shipping.map((item) => ({
                  value: item.id.toString(),
                  title: item.name,
                })) || []
              }
              maxWidth="200px"
            />
          </Col>
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              marginTop: 10,
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Table
              style={{ width: "100%" }}
              pagination={false}
              bordered
              columns={columns}
              dataSource={data}
            />
          </Col>
          {valueSelector !== "-2" && (
            <>
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: 10,
                  // padding: 5,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Title style={{ marginBottom: "12.5px" }} level={5}>
                  Numero de guía
                </Title>
                <InputTextSimple
                  placeHolder="Numero de guía"
                  value={valueGuia}
                  onChange={(e) => setValueGuia(e.target.value)}
                />
              </Col>
              <Col
                span={24}
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: 10,
                  // padding: 5,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Title style={{ marginBottom: "12.5px" }} level={5}>
                  Escriba nota privada
                </Title>
                <SimpleInputTextArea
                  placeHolder="Escriba nota privada"
                  value={valueText}
                  onChange={(e) => setValueText(e.target.value)}
                />
              </Col>
            </>
          )}
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              marginTop: 10,
              // padding: 5,
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Button
              type="primary"
              style={{
                marginLeft: 6,
              }}
              disabled={isLoading}
              onClick={handleGenerate}
            >
              Generar Envío
            </Button>
          </Col>
          {valueSelector === "2" && (
            <Col
              span={24}
              style={{
                width: "100%",
                display: "flex",
                marginTop: 10,
                // padding: 5,
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                type="dashed"
                style={{ marginLeft: 6 }}
                // onClick={changeOrderModal}
              >
                Cotizar Envíos
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </WrapperModalGenerate>
  );
};
