import { Button, Col, Row } from "antd";
import {
  PlusOutlined,
  FilterOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { AdminMenuLayout } from "../../../shared/components";
import { CounterProductsTable } from "./CounterProductsTable";
import { WrapperCard } from "./styled";
import { useCounterProductsTable, useProducts } from "../hooks";
import { TableProducts } from "./TableProducts";
import { useState } from "react";
import { getService } from "../../../shared/api";
import axios from "axios";

export const Products = () => {
  const {
    querySelectorTransform,
    navigateProductsCreate,
    handleQueryTransform,
  } = useProducts();
  const {
    querySelector,
    counterArr,
    getUpdateQuerySelector,
    setQuerySelector,
    getDownloadProductsWithFilters,
  } = useCounterProductsTable({ handleQueryTransform });

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [fileValue, setFileValue] = useState();

  const handleDownload = async () => {
    if (loadingDownload) return;
    setLoadingDownload(true);

    await getService("export-import-discount-products")
      .find()
      .then((res: any) => {
        window.open(res, "_blank");
      })
      .catch(() => {
        // TODO
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  const handleUploadFile = async (e: any) => {
    if (loadingUpload || !e?.target?.files?.length) return;
    setLoadingUpload(true);

    const formData = new FormData();
    formData.append("excel_file", e.target.files[0]);

    await axios
      .post(
        "https://api.tiendahoreca.co/export-import-discount-products",
        formData,
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
          },
        }
      )
      .then(() => {
        setFileValue(undefined);
      })
      .catch(() => {
        // TODO
      })
      .finally(() => {
        setLoadingUpload(false);
      });
  };

  return (
    <AdminMenuLayout pageDescription="Products" title="Products">
      <WrapperCard>
        <Row gutter={[8, 8]}>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 5,
            }}
            xs={24}
          >
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="small"
              onClick={getDownloadProductsWithFilters}
            >
              Descargar lista de productos
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<FilterOutlined />}
              size="small"
              onClick={() => {
                setQuerySelector([]);
                handleQueryTransform([]);
              }}
            >
              Limpiar filtros
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="small"
              onClick={navigateProductsCreate}
            >
              Agregar nuevo producto
            </Button>
            <input
              type="file"
              value={fileValue}
              style={{ display: "none" }}
              multiple={false}
              id="upload-csv"
              onChange={handleUploadFile}
            />
            <Button.Group size="small">
              <Button
                shape="round"
                loading={loadingUpload}
                onClick={() => document.getElementById("upload-csv")?.click?.()}
                size="small"
                icon={<UploadOutlined />}
              >
                Importar productos
              </Button>
              <Button
                shape="round"
                title="Descargar plantilla de productos"
                size="small"
                onClick={handleDownload}
                loading={loadingDownload}
                icon={<DownloadOutlined />}
              />
            </Button.Group>
          </Col>
          <Col xs={24}>
            <CounterProductsTable
              getUpdateQuerySelector={getUpdateQuerySelector}
              querySelector={querySelector}
              counterArr={counterArr}
            />
          </Col>
        </Row>
        <TableProducts fetchQuery={querySelectorTransform} />
      </WrapperCard>
    </AdminMenuLayout>
  );
};
