import { Avatar, Col, Row, Typography } from "antd";
import { MailOutlined, PhoneOutlined, IdcardOutlined } from "@ant-design/icons";

import { Billing_order_info, UserByOrder } from "../../../../redux/@types";
import { WrapperCardUserOrder } from "../styled";

const { Title } = Typography;

export interface ShippingProps {
  id: number;
  name: string;
  address: string;
  user_id: number;
  city_id: number;
  state_id: number;
  lat: null;
  lng: null;
  dni: string;
  details: string;
  main: string;
  integration_nit: null;
  integration_codigo_direccion: null;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  address_name: string;
  address_id: number;
  state_name: string;
  state_integration_id: string;
  city_name: string;
  city_integration_id: string;
  dane_code: string;
  fulfillment_company_id: number;
}
interface Props {
  dataUser: UserByOrder;
  shipping: ShippingProps;
  dataFactura: Billing_order_info[];
  person_type: string;
}

export const InfoUserCard = ({
  shipping,
  dataUser,
  dataFactura,
  person_type,
}: Props) => {
  return (
    <WrapperCardUserOrder>
      <Row justify="start" style={{ width: "100%" }}>
        <Col
          style={{
            display: "flex",
            // padding: 2.5,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Avatar
            size={74}
            style={{
              background: "var(--text-color-blue-dark)",
              // padding: 9,
            }}
            src={`/avatar.png`}
          />
        </Col>
        <Col
          style={{
            display: "flex",
            marginLeft: "5px",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ margin: 0, color: "var(--primary)" }} level={4}>
            {`${dataUser.first_name} ${dataUser.last_name}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <MailOutlined /> {`${dataUser.email}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <PhoneOutlined /> {`${dataUser.phone}`}
          </Title>
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
            <IdcardOutlined /> {`${dataUser.dni}`}
          </Title>
          {dataUser.customer_type && (
          <Title
            style={{
              margin: 0,
              color: "var(--text-color-grey)",
            }}
            level={5}
          >
             {`${dataUser?.customer_type}`}
          </Title>
          )}
        </Col>
      </Row>
      <Row justify="start" style={{ width: "100%" }}>
        {shipping.city_name && shipping.state_name && shipping.address && (
          <>
            <Col
              span={24}
              style={{
                display: "flex",
                marginLeft: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ margin: "7px 0", color: "var(--primary)" }}
                level={4}
              >
                Datos de envío
              </Title>
            </Col>
            <Col
              style={{
                display: "flex",
                marginLeft: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >{`${shipping.address && shipping.address}`}</Title>
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >
                {`${shipping.city_name && shipping.city_name}, ${
                  shipping.state_name && shipping.state_name
                }`}
              </Title>
            </Col>
          </>
        )}
      </Row>
      <Row justify="start" style={{ width: "100%" }}>
        {dataFactura.length > 0 && (
          <>
            <Col
              span={24}
              style={{
                display: "flex",
                marginLeft: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{ margin: "7px 0", color: "var(--primary)" }}
                level={4}
              >
                Datos de Facturación
              </Title>
            </Col>
            <Col
              style={{
                display: "flex",
                marginLeft: "5px",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >
                Tipo de Persona: {`${person_type}`}
              </Title>
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >
                Razón Social: {`${dataFactura?.[0]?.trade_name}`}
              </Title>
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >
                E-mail: {`${dataFactura?.[0]?.email}`}
              </Title>
              <Title
                style={{
                  margin: 0,
                  color: "var(--text-color-grey)",
                }}
                level={5}
              >
                NIT: {`${dataFactura?.[0]?.nit}`}
              </Title>
            </Col>
          </>
        )}
      </Row>
    </WrapperCardUserOrder>
  );
};
